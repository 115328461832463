import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './ui/App';
import { ThemeToggle } from './ui/base/ThemeToggle';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
    <ThemeToggle />
  </React.StrictMode>
);
